import React from 'react';
import version from '../../version.json';

import { Container, Col, Row } from 'reactstrap';

const Footer = (props) => {
    return (
        <Container style={{ backgroundColor: '#efefef' }} className="footer px-0" fluid>
            <Container>
                <Row className="text-center border-top">
                    <Col>
                        <strong>
                            <a href={props.organization.organizationHomePage} target="_blank" rel="noreferrer">
                                {props.organization.organizationName}
                            </a>
                        </strong>{' '}
                        | Call: {props.organization.organizationPhoneNumber} | Email: {props.organization.email}
                    </Col>
                </Row>
            </Container>
            <Container style={{ backgroundColor: '#272822' }} className="text-white px-0" fluid>
                <Row className="text-center text-muted">
                    <Col className="w-100">
                        <img src="https://images.orthotom.com/images/organization/1/largerlogowithR.png?height=45" alt="OrthoTOM Logo" height="45" />
                        Powered by <span className="kabel-book">OrthoTom &copy; {new Date().getFullYear()}</span> | Visit{' '}
                        <a href="https://www.orthotom.com" className="text-white">
                            https://www.orthotom.com
                        </a>{' '}
                        to get yours. |
                        <a href="https://www.orthotom.com/tos.html" className="text-muted ms-1">
                            Terms of Service
                        </a>{' '}
                        |
                        <a href="https://www.orthotom.com/privacypolicy.html" className="text-muted ms-1">
                            Privacy Policy
                        </a>
                        <span className="float-end me-2">
                            v.{version.version}.{version.build}
                        </span>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};

export default Footer;
